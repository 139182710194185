import { 
	library, 
	dom, 
	config } from '@fortawesome/fontawesome-svg-core';

import { 
	faEdit as falEdit, 
	faQuoteLeft as falQuoteLeft, 
	faQuoteRight as falQuoteRight, 
	faCheck as falCheck, 
	faTimes as falTimes, 
	faChevronDown as falChevronDown, 
	faChevronUp as falChevronUp, 
	faHeartbeat as falHeartbeat, 
	faTombstoneAlt as falTombstoneAlt, 
	faBalanceScale as falBalanceScale, 
	faEraser as falEraser, 
	faFileUser as falFileUser } from '@fortawesome/pro-light-svg-icons';

import { 
	faSearch as farSearch, 
	faCircle as farCircle, 
	faChevronDown as farChevronDown, 
	faChevronLeft as farChevronLeft, 
	faChevronRight as farChevronRight, 
	faCheck as farCheck, 
	faPlus as farPlus, 
	faEnvelopeOpen as farEnvelopeOpen, 
	faEnvelope as farEnvelope, 
	faFilePdf as farFilePDF, 
	faFileArchive,
	faFileSpreadsheet,
	faFileImage,
	faFilePowerpoint,
	faFileWord,
	faFileUser as farFileUser, 
	faFile as farFile, 
	faAddressCard as farAddressCard, 
	faGlobeAmericas as farGlobeAmericas, 
	faCommentLines as farCommentLines, 
	faLink as fasLink, 
	faDownload as fasDownload, 
	faTools as farTools, 
	faFileAlt as farFileAlt } from '@fortawesome/pro-regular-svg-icons';

import { 
	faPlayCircle as faPlayCircle, 
	faChevronRight, 
	faChevronLeft, 
	faChevronDown, 
	faCircle as fasCircle, 
	faSearch as fasSearch, 
	faChevronUp as fasChevronUp, 
	faEdit as fasEdit, 
	faSpinner as fasSpinner, 
	faUser as fasUser, 
	faShoppingCart as fasShoppingCart, 
	faHashtag as fasHashtag, 
	faBookmark as fasBookmark, 
	faTimesCircle as fasTimesCircle, 
	faInfoCircle as fasInfoCircle, 
	faQuoteLeft as fasQuoteLeft, 
	faQuoteRight as fasQuoteRight, 
	faFile as fasFile } from '@fortawesome/pro-solid-svg-icons';

import { 
	faFacebookF, 
	faTwitter, 
	faLinkedin, 
	faInstagram, 
	faLinkedinIn, 
	faMediumM } from '@fortawesome/free-brands-svg-icons';

config.searchPseudoElements = true;
config.keepOriginalSource = false;

// light
library.add(
	falEdit, 
	falQuoteLeft, 
	falQuoteRight, 
	falCheck, 
	falTimes, 
	falChevronDown, 
	falChevronUp, 
	falHeartbeat, 
	falTombstoneAlt,falBalanceScale, 
	falEraser, 
	falFileUser
	);

// regular
library.add(
	farSearch, 
	farCircle, 
	farChevronLeft, 
	farChevronRight, 
	farCheck, 
	farPlus,farChevronDown, 
	farEnvelopeOpen, 
	farEnvelope,
	farFilePDF, 
	farFileUser, 
	farFile, 
	faFileArchive,
	faFileSpreadsheet,
	faFileImage,
	faFilePowerpoint,
	faFileWord,
	fasLink, 
	fasDownload, 
	farAddressCard, 
	farGlobeAmericas, 
	farCommentLines, 
	farTools, 
	farFileAlt
	);
// solid
library.add(
	faPlayCircle, 
	faChevronRight, 
	faChevronLeft, 
	faChevronDown, 
	fasCircle, 
	fasSearch, 
	fasChevronUp, 
	fasEdit, 
	fasSpinner, 
	fasUser, 
	fasShoppingCart, 
	fasHashtag, 
	fasBookmark, 
	fasTimesCircle, 
	fasInfoCircle, 
	fasQuoteLeft, 
	fasQuoteRight, 
	fasFile
	);
// brands
library.add(
	faFacebookF, 
	faTwitter, 
	faLinkedin, 
	faInstagram, 
	faLinkedinIn, 
	faMediumM
	);

dom.i2svg();
dom.watch();