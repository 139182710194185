import 'bootstrap/js/dist/modal';

export class ProtectedVideo {

  init () {
    let self = this;
    $('[data-protected-vimeo-video]').each(function () {
      self.bind($(this));
    })
  }

  bind ($anchor) {
    console.log('ProtectedVideo', $anchor);

    const self = this;

    let vimeoId = $anchor.data('protected-vimeo-video');
    let target = $anchor.data('target');
    let $targetModal = $(target);



    $anchor.on('click', function (e) {
      e.preventDefault();

      if (localStorage.getItem('submitted-email')) {
        self.revealVideo($anchor, $targetModal);
      } else {
        $targetModal.modal();

      }

    });

    let $form = $targetModal.find('form');

    $form.on('submit', function (e) {
      e.preventDefault();

      let emailVal = $form.find('#email').val();

      $form.find('button[type=submit]').hide();
      $form.find('button.is-submitting').show();

      $.ajax(window.location.href, {
        // headers: {
        //   Accept: "text/plain; charset=utf-8",
        // },
        method: 'POST',
        data: $form.serialize(),
        dataType: 'json',
        accept: {json: 'application/json'},
        success: function (data) {
          if (data.success) {
            localStorage.setItem('submitted-email', emailVal);
            console.log('onSuccess',data );
            self.revealVideo($anchor, $targetModal);
          }
        },
        error: function (data) {
          console.log('onError',data );
          if (data.responseJSON.error ) {
            let error = data.responseJSON.error;

            error = error.replace(/.*RFC 2822.*/, 'The given email address was not valid eg. name@example.com');

            $form.find('[data-error-alert]').html(error).show();
          }
        },
        complete: function () {

          console.log('onComplete');

          $form.find('button[type=submit]').show();
          $form.find('button.is-submitting').hide();

        }
      });

    });
    //
    // $downloadLink.on('click', function (e) {
    //   $targetModal.modal('hide');
    // });


  }

  revealVideo($anchor, $targetModal) {
    $targetModal.modal('hide');

    $anchor.html(
      '<iframe src="https://player.vimeo.com/video/'+$anchor.data('protected-vimeo-video')+'?autoplay=1" width="100%" frameborder="0" allow="autoplay; fullscreen" allowfullscreen title="This is a protected vimeo video."></iframe>\n'
    );


    // let $downloadLink = $targetModal.find('.protected-download-link');
    // $targetModal.find('[download-step-1]').addClass('in') // TODO: animate this
    // $downloadLink.show();
    // $downloadLink.css({opacity: '0'});
    // $downloadLink.css({opacity: '1'});
    // $downloadLink.attr('href', protectedData.url);
  }
}
