import styles from "../css/main.scss";
import 'lazysizes/lazysizes';
import 'lazysizes/plugins/parent-fit/ls.parent-fit';
import 'lazysizes/plugins/bgset/ls.bgset';
import 'lazysizes/plugins/respimg/ls.respimg';
import '../js/bootstrap';
import "../js/fontawesome";
import '../js/glide';

// import '../js/mailchimp';
import UIBindings from "../js/ui-bindings";
import { PageTimer } from "../js/pagetimer";
import { ExternalLinks } from "../js/externallinks";
import { ShareHandler } from "../js/share";
import { Forms } from "../js/form";
import { ProtectedVideo } from "./protected-video";

(new PageTimer()).logToConsole();
(new ExternalLinks('body')).addLinkTarget();
(new ShareHandler()).build();
(new Forms()).validate();
(new ProtectedVideo()).init();

const UIBinding = new UIBindings();
UIBinding.formSubmitButton();


// Accept HMR as per: https://webpack.js.org/api/hot-module-replacement#accept
if (module.hot) {
    module.hot.accept();
}
$(function() {
  // console.log('hello');

  // $('.dropdown').on('show.bs.dropdown', function() {
  //     $(this).find('.dropdown-menu').first().stop(true, true).slideDown();
  //   });

  //   // Add slideUp animation to Bootstrap dropdown when collapsing.
  //   $('.dropdown').on('hide.bs.dropdown', function() {
  //     $(this).find('.dropdown-menu').first().stop(true, true).slideUp();
  //   });

  if(document.getElementById("fields-emailAddress")){
    document.getElementById("fields-emailAddress").onblur = function(){
      var element = document.getElementById("fields-emailAddress");
  
      if(element.value.indexOf('@gmail') !== -1 || element.value.indexOf('@mail') !== -1  || element.value.indexOf('@aol') !== -1  || element.value.indexOf('@hotmail') !== -1 || element.value.indexOf('@webmail') !== -1  ||element.value.indexOf('@yahoo') !== -1 || element.value.indexOf('@outlook') !== -1 || element.value.indexOf('@zoho') !== -1 || element.value.indexOf('@icloud') !== -1 || element.value.indexOf('@gmx') !== -1 || element.value.indexOf('@protonmail') !== -1 || element.value.indexOf('@thunderbird') !== -1 || element.value.indexOf('@yandex') !== -1){
        element.value = null;
        element.parentElement.classList.add("is-invalid");
        var node = document.createElement("p");
        node.id = "emailWarning";
        node.classList.add("invalid-feedback");
        node.classList.add("d-inline-block");     
        var textnode = document.createTextNode("Please use a valid business email address");
        node.appendChild(textnode);  
         element.parentElement.appendChild(node)
      } else{
        element.parentElement.classList.remove("is-invalid");
        var warning = document.getElementById("emailWarning");
        if(warning){
          element.parentElement.removeChild(warning);
        }
      }
      
    }
  }


  $('.dropdown-menu a.dropdown-toggle').on('click', function(e) {
    if (!$(this).next().hasClass('show')) {
      $(this).parents('.dropdown-menu').first().find('.show').removeClass('show');
    }
    var $subMenu = $(this).next('.dropdown-menu');
    $subMenu.toggleClass('show');


    $(this).parents('li.nav-item.dropdown.show').on('hidden.bs.dropdown', function(e) {
      $('.dropdown-submenu .show').removeClass('show');
    });


    return false;
  });

    !function(){var t=function(){var t=document.createElement("script");t.src="https://ws.audioeye.com/ae.js",t.type="text/javascript",t.setAttribute("async",""),document.getElementsByTagName("body")[0].appendChild(t)};"complete"!==document.readyState?window.addEventListener?window.addEventListener("load",t):window.attachEvent&&window.attachEvent("onload",t):t()}()

    console.log('audioeye');
});
